<template>
  <div class="chat-record-container">
    <a-spin :spinning="isFirstListLoading">
      <div class="first-list">
        <p class="mb-16 text-16 font-medium">员工</p>
        <a-input-search v-model:value="queryForStaff.staffName"
                        class="mb-8"
                        style="width: 168px"
                        placeholder="请输入员工姓名"
                        @change="searchByStaffName" />

        <ul v-if="staff.list.length"
            ref="firstListRef"
            @scroll="loadMoreFirst">
          <li v-for="data in staff.list"
              :key="data.id"
              class="avatar-item cursor-pointer"
              :class="{active: data.active}"
              @click="selectStaff(data)">
            <a-image v-if="data.avatar"
                     :src="data.avatar"
                     alt="头像"
                     width="32px"
                     height="32px"
                     :preview="false" />
            <svg-icon v-else
                      class="text-32"
                      type="iconmorentouxiang" />

            <span class="ml-8 line-clamp-1"
                  style="max-width: 130px;">{{ data.name }}</span>
          </li>
          <li v-if="staff.loading && !staff.list.length"
              class="mt-8 text-center">
            <a-spin />
          </li>
        </ul>
        <a-empty v-else
                 :image="simpleImage"
                 description="暂无员工" />
      </div>
    </a-spin>

    <a-spin :spinning="isSecondListLoading">
      <div ref="secondListRef"
           class="second-list">
        <div class="avatar-item pt-0"
             :class="{invisible: !currentActive.first.avatar}">
          <a-image v-if="currentActive.first.avatar"
                   :src="currentActive.first.avatar"
                   alt="员工头像"
                   width="32px"
                   height="32px"
                   :preview="false" />
          <svg-icon v-else
                    type="iconmorentouxiang"
                    class="text-32" />
          <span class="ml-8">{{currentActive.first.name}}</span>
        </div>

        <a-input-search v-model:value="queryForReply.memberName"
                        class="ml-24"
                        style="width: 240px"
                        placeholder="请输入客户、同事、群聊名称"
                        @change="debounceSearchReplyByQuery" />

        <a-tabs v-model:activeKey="queryForReply.type"
                class="replay-tabs"
                size="small"
                :tabBarGutter="24"
                @change="searchReplyByQuery">
          <a-tab-pane v-for="tab in replyTypeList"
                      :key="tab.key"
                      :tab="tab.name" />
        </a-tabs>

        <ul v-if="reply.list.length"
            ref="secondListRef"
            @scroll="loadMoreSecond">
          <li v-for="data in reply.list"
              :key="data.id"
              class="avatar-item cursor-pointer text-12"
              :class="{active: data.active}"
              @click="selectReply(data)">
            <svg-icon v-if="queryForReply.type === 'group'"
                      class="text-32"
                      type="iconqunliao" />
            <a-image v-else-if="data.avatar"
                     :src="data.avatar"
                     alt="头像"
                     width="32px"
                     height="32px"
                     :preview="false" />
            <svg-icon v-else
                      class="text-32"
                      type="iconmorentouxiang"></svg-icon>

            <div class="flex-1 mr-24 ml-8">
              <div class="avatar-item__title">
                <span class="line-clamp-1"
                      style="max-width: 100px">{{ data.name }}</span>
                <span class="text-color-999">{{ computeLastReplyTime(data.lastMsgTime) }}</span>
              </div>
              <span class="text-color-999 line-clamp-1"
                    style="maxWidth: 200px">{{ data.simpleContent|| '[暂无记录]' }}</span>
            </div>
          </li>
          <li v-if="reply.loading && !isSecondListLoading"
              class="mt-8 text-center">
            <a-spin />
          </li>
        </ul>
        <a-empty v-else
                 :image="simpleImage"
                 description="暂无存档数据" />
      </div>
    </a-spin>

    <record-list :reply="currentActive.second"
                 :type="queryForReply.type"
                 :sender-id="currentActive.first.id"
                 :loading="reply.loading"
                 :staffId="currentActive.first.id"
                 :customerId="currentActive.second.id">
    </record-list>

  </div>
</template>

<script>
import _ from "lodash";
import { defineComponent, ref, onMounted, reactive } from "vue";
import { Tabs, Image, Empty } from "ant-design-vue";

import { computeLastReplyTime } from "@/global";
import RecordList from "./RecordList";
import SvgIcon from "@/components/SvgIcon";

import chatRecordApi from "@/service/api/chatRecord";

export default defineComponent({
  name: "ChatRecordByStaff",

  components: {
    RecordList,
    SvgIcon,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    AImage: Image,
    AEmpty: Empty,
  },

  setup() {
    const currentActive = reactive({
      first: {},
      second: {},
    });
    const initCurrentActive = () => {
      _.assign(currentActive, {
        first: {},
        second: {},
      });
    };

    // 第一列员工列相关
    const isFirstListLoading = ref(false);
    const queryForStaff = reactive({
      staffName: "",
      page: 0,
      size: 20,
    });
    const staff = reactive({
      list: [],
      loading: false,
      finished: false,
    });

    const initStaff = () => {
      _.assign(staff, {
        list: [],
        loading: false,
        finished: false,
      });

      queryForStaff.page = 0;
    };

    async function selectStaff(data) {
      queryForReply.type = "staff_customer";

      currentActive.first.active = false;
      currentActive.first = data;
      currentActive.first.active = true;

      initReply();

      getReplyList();
    }

    const searchByStaffName = _.debounce(async () => {
      isFirstListLoading.value = true;

      initStaff();

      await loadStaffList();

      isFirstListLoading.value = false;

      if (!_.isEmpty(staff.list)) {
        selectStaff(staff.list[0]);
        return;
      }

      initCurrentActive();
      initReply();
    }, 500);

    const firstListRef = ref();

    const loadMoreFirst = _.debounce(() => {
      if (!firstListRef.value) {
        return;
      }

      const { scrollHeight, scrollTop, clientHeight } = firstListRef.value;

      if (scrollHeight - clientHeight - scrollTop < 80 && !staff.finished) {
        loadStaffList();
      }
    }, 500);

    async function loadStaffList() {
      staff.loading = true;

      const res = await chatRecordApi.getStaffList(queryForStaff);

      staff.list = _.concat(staff.list, res.data);
      queryForStaff.page++;
      staff.loading = false;

      staff.finished = staff.list.length >= res.total;
    }

    async function getStaffList() {
      isFirstListLoading.value = true;
      await loadStaffList();
      isFirstListLoading.value = false;

      if (!_.isEmpty(staff.list)) {
        selectStaff(staff.list[0]);
        return;
      }

      initCurrentActive();
    }

    // 第二列相关
    const replyTypeList = [
      { key: "staff_customer", name: "客户" },
      { key: "staff_staff", name: "同事" },
      { key: "group", name: "群聊" },
    ];

    const isSecondListLoading = ref(false);
    const queryForReply = reactive({
      type: "staff_customer",
      senderId: undefined,
      memberName: "",
      page: 0,
    });
    const reply = reactive({
      list: [],
      loading: false,
      finished: false,
    });

    const initReply = () => {
      _.assign(reply, {
        list: [],
        loading: false,
        finished: false,
      });

      queryForReply.page = 0;
    };

    const searchReplyByQuery = async () => {
      if (!currentActive.first.id) {
        return;
      }

      initReply();

      isSecondListLoading.value = true;
      await loadReplyList();
      isSecondListLoading.value = false;

      if (_.isEmpty(reply.list)) {
        currentActive.second = {};
        return;
      }

      selectReply(reply.list[0]);
    };

    const debounceSearchReplyByQuery = _.debounce(searchReplyByQuery, 500);

    function selectReply(data) {
      currentActive.second.active = false;
      currentActive.second = data;
      data.active = true;
    }

    const secondListRef = ref();

    const loadMoreSecond = _.debounce(() => {
      if (!secondListRef.value) {
        return;
      }

      const { scrollHeight, scrollTop, clientHeight } = secondListRef.value;

      if (scrollHeight - clientHeight - scrollTop < 80 && !reply.finished) {
        loadReplyList();
      }
    }, 500);

    async function loadReplyList() {
      const apiList = {
        staff_customer: chatRecordApi.getStaffsCustomerList,
        staff_staff: chatRecordApi.getStaffsWorkerList,
        group: chatRecordApi.getStaffsGroupList,
      };

      reply.loading = true;
      const res = await apiList[queryForReply.type](queryForReply);
      reply.list = _.concat(reply.list, res.data);
      queryForReply.page++;
      reply.loading = false;

      reply.finished = reply.list.length >= res.total;
    }
    async function getReplyList() {
      queryForReply.senderId = currentActive.first.id;

      isSecondListLoading.value = true;
      await loadReplyList();
      isSecondListLoading.value = false;

      if (!_.isEmpty(reply.list)) {
        selectReply(reply.list[0]);
        return;
      }

      currentActive.second = {};
    }

    onMounted(() => {
      getStaffList();
    });

    return {
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      currentActive,

      isFirstListLoading,
      staff,
      queryForStaff,

      selectStaff,
      searchByStaffName,

      firstListRef,
      loadMoreFirst,

      isSecondListLoading,
      replyTypeList,
      reply,
      queryForReply,
      searchReplyByQuery,
      debounceSearchReplyByQuery,
      selectReply,

      secondListRef,
      loadMoreSecond,

      computeLastReplyTime,
    };
  },
});
</script>
<style lang='less' scoped>
:deep(.ant-tabs-bar) {
  margin-bottom: 16px;
}
:deep(.ant-tabs .ant-tabs-small-bar .ant-tabs-tab) {
  padding-top: 16px;
  padding-bottom: 12px;
  color: @gray;
}
:deep(.ant-tabs-tab-active) {
  color: @color-primary;
}
</style>