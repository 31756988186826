<template>
  <a-spin :spinning='spinning'>

    <template v-if="!isEnable">
      <div class="enable-container">
        <svg-icon class="icon-cundang"
                  type="iconhuihuacundang" />
        <div>
          <div class="title font-16 fw-500 pb16">会话存档</div>
          <div class="desc gray">开启会话存档功能，系统将永久保存员工与客户的文字、图片、语音聊天内容，并且可以根据关键字搜索，协助企业监控员工服务质量和风险控制。</div>
        </div>
      </div>
      <router-link :to="{name: 'system_setting_chat_record', query:{'isBack': true}}">
        <a-button class="enable-btn"
                  type="primary"
                  style="width: 106px;height: 36px">去开通</a-button>
      </router-link>
    </template>

    <template v-else>
      <a-tabs v-model:activeKey="searchType">
        <a-tab-pane key="staff"
                    tab="员工检索"></a-tab-pane>
        <a-tab-pane key="customer"
                    tab="客户检索"></a-tab-pane>
        <a-tab-pane key="group"
                    tab="群聊检索"></a-tab-pane>
      </a-tabs>
      <component :is="componentsList[searchType]"></component>
    </template>
  </a-spin>
</template>

<script>
import { defineComponent, ref } from "vue";
import { Tabs, Image } from "ant-design-vue";
import ChatRecordByStaff from "@/views/corpRiskControl/chatRecord/ChatRecordByStaff";
import ChatRecordByCustomer from "@/views/corpRiskControl/chatRecord/ChatRecordByCustomer";
import ChatRecordByGroup from "@/views/corpRiskControl/chatRecord/ChatRecordByGroup";

import SvgIcon from "@/components/SvgIcon";
import RecordList from "./RecordList";

import corpSettingApi from "@/service/api/corpSetting";

export default defineComponent({
  name: "ChatRecordIndex",

  components: {
    SvgIcon,
    RecordList,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    AImage: Image,
    ChatRecordByStaff,
    ChatRecordByCustomer,
    ChatRecordByGroup,
  },

  setup() {
    const spinning = ref(true);
    const isEnable = ref(true);

    async function getEnable() {
      const { setConversationArchive, setClientSecret } =
        await corpSettingApi.getConfigurations();

      isEnable.value =
        setConversationArchive.status !== "nil" &&
        setClientSecret.status !== "nil";

      spinning.value = false;
    }

    const searchType = ref("staff");
    const componentsList = {
      staff: ChatRecordByStaff,
      customer: ChatRecordByCustomer,
      group: ChatRecordByGroup,
    };

    getEnable();
    return {
      spinning,
      isEnable,
      searchType,
      componentsList,
    };
  },
});
</script>
<style lang='less' scoped>
:deep(.ant-tabs-bar) {
  margin-bottom: 16px;
}
:deep(.ant-tabs-tab) {
  .font-16();
  color: @gray;
}
:deep(.ant-tabs-tab-active) {
  color: @color-primary;
}
.enable-container {
  position: relative;
  display: flex;
  align-items: center;

  .icon-cundang {
    margin-right: 24px;
    font-size: 100px;
  }

  .desc {
    width: 849px;
  }
}
.enable-btn {
  position: absolute;
  top: 50px;
  right: 0;
  transform: translateY(-50%);
}
</style>